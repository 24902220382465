<template>
  <section id="lookup-order">
    <b-card-actions
      ref="searchbox"
      action-collapse
      title="Order Search"
    >
      <b-form @submit.prevent>
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Serial Number"
              label-for="ol-serial-number"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="HardDriveIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="ol-serial-number"
                  v-model="inputSerialNumber"
                  placeholder="Serial Number"
                  :disabled="disabledSerial"
                  @focus="inputFocus('serial')"
                  @blur="inputBlur('serial')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Tracking Number"
              label-for="ol-tracking-number"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="TruckIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="ol-tracking-number"
                  v-model="inputTrackingNumber"
                  placeholder="Tracking Number"
                  :disabled="disabledTracking"
                  @focus="inputFocus('tracking')"
                  @blur="inputBlur('tracking')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Invoice Number"
              label-for="ol-invoice-number"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="BoxIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="ol-invoice-number"
                  v-model="inputInvoiceNumber"
                  v-mask="'######'"
                  placeholder="Invoice Number"
                  :disabled="disabledInvoice"
                  @focus="inputFocus('invoice')"
                  @blur="inputBlur('invoice')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              :label="placeholderOrderNumber"
              label-for="ol-order-number"
            >
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="BoxIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="ol-order-number"
                  v-model="inputOrderNumber"
                  v-mask="maskOrderNumber"
                  :placeholder="placeholderOrderNumber"
                  :disabled="disabledOrder"
                  @focus="inputFocus('order')"
                  @blur="inputBlur('order')"
                />
              </b-input-group>
            </b-form-group>
          </b-col>

          <!-- submit and reset -->
          <b-col>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
              class="mr-1"
              :disabled="disableSearch"
              @click="
                search();
                multipleInvoices = '';
              "
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              class="mr-1"
              variant="outline-secondary"
              @click="
                reset();
                multipleInvoices = '';
              "
            >
              Reset
            </b-button>
            <b-button
              v-if="dataInvoice.queryCount > 0"
              v-clipboard:copy="currentRouteName"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
            >Copy Invoice URL</b-button>
          </b-col>
          <b-col>
            <b-form-group class="mt-n2 pt-1">
              <b-form-checkbox-group
                v-model="selectOrderNumberType"
                :disabled="disabledOrder"
                :options="optionsOrderNumberType"
                switches
                @click="selectOrderNumberType = ''"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-card-actions>
    <div
      v-if="searching"
      class="text-center"
    >
      {{ messageText }}
    </div>
    <b-progress
      v-if="searching"
      :value="barValue"
      max="100"
    />
    <b-row
      v-if="errorMessage"
      class="match-height"
    >
      <b-col
        cols="12"
        class="my-0"
      >
        <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Error Occured
          </h4>
          <div class="alert-body">
            <span>{{ errorMessage }}</span>
          </div>
        </b-alert>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card
          v-if="multipleInvoices.length > 0"
          class="py-0"
        >
          <span
            v-for="invoice in multipleInvoices"
            :key="invoice.id"
          >
            <router-link :to="'/query/order/?invoice=' + invoice.id">
              {{ invoice.id }}
            </router-link>
          </span>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="match-height">
      <!-- Open Tickets -->
      <b-col
        v-if="dataInvoiceTickets.queryCount || searchingInvoiceTicket"
        lg="12"
      >
        <tickets :table-data="dataInvoiceTickets" />
      </b-col>
      <!--/ Open Tickets -->
    </b-row>
    <ops-info
      v-if="dataInvoiceOps.queryCount || searchingInvoiceOps"
      :data="dataInvoiceOps"
    />
    <invoice
      v-if="dataInvoice.queryCount || searching"
      :table-data="dataInvoiceLineItems"
      :invoice-data="dataInvoice"
    />
    <b-card v-if="searchingInvoiceImages">
      Searching Invoice Images
    </b-card>
    <b-carousel
      v-if="dataInvoiceImages.queryCount > 0 && !searchingInvoiceImages"
      controls
      indicators
      style="background-size: cover;"
    >
      <b-carousel-slide
        v-for="image in dataInvoiceImages.results"
        :key="image.src"
        :img-src="image.src"
      />
    </b-carousel>
  </section>
</template>

<script>
import {
  BFormCheckboxGroup,
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BInputGroup,
  BProgress,
  BInputGroupPrepend,
  BCarousel,
  BCarouselSlide,
  BAlert,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mask } from 'vue-the-mask'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Tickets from '../../components/_ticket/TicketsCard.vue'
import Invoice from '../../components/_invoice/InvoiceCard.vue'
import OpsInfo from './OrderOpsInfo.vue'

export default {
  components: {
    BFormCheckboxGroup,
    BCardActions,
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BInputGroup,
    BProgress,
    BInputGroupPrepend,
    BCarousel,
    BCarouselSlide,
    BAlert,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Tickets,
    Invoice,
    OpsInfo,

  },
  directives: {
    Ripple,
    mask,
  },
  data() {
    return {
      message: 'copied text',
      multipleInvoices: [],
      messageText: '',
      placeholderOrderNumber: 'Order Number - Select Order Type',
      inputInvoiceNumber: '',
      inputOrderNumber: '',
      inputSerialNumber: '',
      inputTrackingNumber: '',
      maskOrderNumber: '#################',
      selectOrderNumberType: '',
      disabledSerial: false,
      disabledTracking: false,
      disabledInvoice: false,
      disabledOrder: false,
      optionsOrderNumberType: [
        { text: 'Amazon', value: 'amazon' },
        { text: 'eBay', value: 'ebay' },
        { text: 'Newegg', value: 'newegg' },
        { text: 'Walmart', value: 'walmart' },
        { text: 'Website', value: 'website' },
      ],
      errorMessage: '',
      timer: 0,
      barValue: 0,
      searching: false,
      searchingInvoiceTicket: false,
      searchingInvoiceOps: false,
      searchingInvoiceImages: false,
      dataInvoice: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        result: {
          txnID: '',
          refNumber: '',
          timeCreated: '',
          timeModified: '',
          shipMethodRef_FullName: '',
          other: '',
          subtotal: 0.0,
          appliedAmount: 0.0,
          isPaid: true,
          balanceRemaining: 0.0,
          memo: '',
          salesRepRef_ListID: '',
          customerRef_FullName: '',
          salesRepRef_FullName: '',
          billAddress_Addr1: '',
          billAddress_Addr2: '',
          billAddress_Addr3: '',
          billAddress_Addr4: '',
          billAddress_Addr5: '',
          billAddress_City: '',
          billAddress_State: '',
          billAddress_PostalCode: '',
          billAddress_Country: '',
          shipAddress_Addr1: '',
          shipAddress_Addr2: '',
          shipAddress_Addr3: '',
          shipAddress_Addr4: '',
          shipAddress_Addr5: '',
          shipAddress_City: '',
          shipAddress_State: '',
          shipAddress_PostalCode: '',
          shipAddress_Country: '',
        },
      },
      dataInvoiceLineItems: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            txnLineID: '',
            itemRef_FullName: '',
            desc: '',
            quantity: 0.0,
            rate: 0.0,
            amount: 0.0,
          },
        ],
      },
      dataSerialNumber: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            id: '',
            serialNumber: '',
          },
        ],
      },
      dataInvoiceTickets: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            ticketNo: 0,
            status: '',
            statusColor: '',
            operator: [],
            startDate: '',
            updateDate: '',
            summary: '',
            contact: '',
            queue: '',
          },
        ],
      },
      dataInvoiceOps: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            ID: 0,
            UID: '',
            timeCreated: '',
            timeModified: '',
            invoiceID: '',
            approvedByID: '',
            builtByID: '',
            buildCompleteDate: '',
            buildComplete: false,
            noOfUnits: 0,
            serialNumbers: [
              {
                ID: 0,
                UID: '',
                timeCreated: '',
                timeModified: '',
                invoiceID: '',
                addedBy: '',
                active: false,
                serialNumber: '',
                model: '',
                pid: '',
              },
            ],
            substitution: false,
            dropShip: false,
            status: '',
            shipDate: '',
            shipDateActual: '',
            invoiceDate: '',
            invoiceProduct: '',
            isShipped: false,
            isDelivered: false,
            tracking: '',
            deliverDateActual: '',
            builtBy: '',
            approvedBy: '',
          },
        ],
      },
      dataTrackingNumber: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        result: {
          trackingno: '',
          trackingcarrier: '',
          pickupdate: '',
          pickuplocation: '',
          deliverydate: '',
          status: '',
          class: '',
          destState: '',
          destCity: '',
          invoiceNo: '',
          eventSummary: [
            {
              date: '',
              time: '',
              gmtOffset: '',
              dateTime: '',
              status: '',
              location: '',
              details: '',
              isException: false,
              isDelivered: false,
              isDelException: false,
              isClearanceDelay: false,
            },
          ],
        },
      },
      dataOrderNumber: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            txnID: '',
            refNumber: '',
            salesRepRef_FullName: '',
          },
        ],
      },
      dataInvoiceImages: {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            src: '',
          },
        ],
      },
    }
  },
  computed: {
    currentRouteName() {
      return window.location
    },
    disableSearch() {
      if (
        !this.inputInvoiceNumber
        && !this.inputOrderNumber
        && !this.inputSerialNumber
        && !this.inputTrackingNumber
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    selectOrderNumberType(val) {
      if (val === 'amazon') {
        this.inputFocus('order')
        this.maskOrderNumber = '###-#######-#######'
        this.placeholderOrderNumber = 'Order Number - (###-#######-#######)'
      } else if (val === 'ebay') {
        this.inputFocus('order')
        this.maskOrderNumber = '##-#####-#####'
        this.placeholderOrderNumber = 'Order Number - (##-#####-#####)'
      } else if (val === 'newegg') {
        this.inputFocus('order')
        this.maskOrderNumber = '#########'
        this.placeholderOrderNumber = 'Order Number - (#########)'
      } else if (val === 'walmart') {
        this.inputFocus('order')
        this.maskOrderNumber = '#############'
        this.placeholderOrderNumber = 'Order Number - (#############)'
      } else if (val === 'website') {
        this.inputFocus('order')
        this.maskOrderNumber = '######'
        this.placeholderOrderNumber = 'Order Number - (######)'
      } else {
        this.inputBlur('order')
        this.maskOrderNumber = '#################'
        this.placeholderOrderNumber = 'Order Number - Select Order Type'
      }
    },
    '$route.query.invoice': {
      handler(val) {
        if (val > 0) {
          this.searching = true
          this.reset()
          this.inputInvoiceNumber = val
          this.search()
        }
        if (!val) {
          this.reset()
        }
      },
      deep: true,
      immediate: true,
    },
  },
  async created() {
    if (this.$route.query.invoice) {
      this.inputInvoiceNumber = this.$route.query.invoice
      await this.search()
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      if (this.barValue === 100) {
        this.barValue = 0
      }
      this.barValue += 5
    }, 600)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
    this.barValue = null
  },
  methods: {
    onCopy() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Invoice URL copied',
          icon: 'BellIcon',
        },
      })
    },
    onError() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed to copy url!',
          icon: 'BellIcon',
        },
      })
    },
    async search() {
      this.errorMessage = ''
      this.messageText = ''
      this.searching = true
      if (this.inputSerialNumber) {
        this.messageText = `searching for serial number: ${this.inputSerialNumber}`
        await this.searchSerial()
        if (this.dataSerialNumber.queryCount === 1) {
          this.messageText = `found invoice ${this.dataSerialNumber.results[0].id}, searching that invoice`
          this.inputSerialNumber = ''
          this.inputInvoiceNumber = this.dataSerialNumber.results[0].id
          this.inputBlur('serial')
          this.inputFocus('invoice')
          this.search()
        } else if (this.dataSerialNumber.queryCount > 1) {
          this.errorMessage = `this serial number is attached to ${this.dataSerialNumber.queryCount} invoice numbers. Please choose an invoice.`
          this.searching = false
          this.multipleInvoices = this.dataSerialNumber.results
        } else {
          const tempSerial = this.inputSerialNumber
          this.reset()
          this.errorMessage = `serial number ${tempSerial} not found, try another`
        }
      } else if (this.inputTrackingNumber) {
        this.messageText = `searching for tracking number: ${this.inputTrackingNumber}`
        await this.searchTracking()
        if (this.dataTrackingNumber.queryCount === 1) {
          this.messageText = `found invoice ${this.dataTrackingNumber.result.invoiceNo}, searching that invoice`
          this.inputTrackingNumber = ''
          this.inputInvoiceNumber = this.dataTrackingNumber.result.invoiceNo
          this.inputBlur('tracking')
          this.inputFocus('invoice')
          this.search()
        } else {
          const tempTracking = this.inputTrackingNumber
          this.reset()
          this.errorMessage = `tracking number ${tempTracking} not found, try another`
        }
      } else if (this.inputOrderNumber && this.selectOrderNumberType) {
        this.messageText = `searching for ${this.selectOrderNumberType} order number ${this.inputOrderNumber}`
        // 114-6399443-2035443
        await this.searchOrder()
        if (this.dataOrderNumber.queryCount === 1) {
          this.messageText = `found invoice ${this.dataOrderNumber.results[0].refNumber}, searching that invoice`
          this.inputOrderNumber = ''
          this.inputInvoiceNumber = this.dataOrderNumber.results[0].refNumber
          this.inputBlur('order')
          this.inputFocus('invoice')
          this.search()
        } else if (this.dataOrderNumber.queryCount > 1) {
          this.errorMessage = `this order number is attached to ${this.dataOrderNumber.queryCount} invoice numbers. Please choose an invoice.`
          this.searching = false
          this.multipleInvoices = this.dataOrderNumber.results
        } else {
          const tempOrderNumber = this.inputOrderNumber
          this.reset()
          this.errorMessage = `order number ${tempOrderNumber} not found, try another`
        }
      } else if (this.inputOrderNumber && !this.selectOrderNumberType) {
        this.errorMessage = 'please select a valid order number type'
        this.searching = false
      } else if (this.inputInvoiceNumber) {
        this.messageText = `searching for invoice number: ${this.inputInvoiceNumber}`
        await this.searchInvoice()
        if (this.dataInvoice.queryCount === 1) {
          this.inputInvoiceNumber = this.dataInvoice.result.refNumber
          if (this.$route.query.invoice !== this.inputInvoiceNumber) {
            this.addHashToLocation(`?invoice=${this.inputInvoiceNumber}`)
          }
          this.searchInvoiceImages()
          this.searchInvoiceLines()
          this.searchInvoiceTickets()
          this.searchInvoiceOps()
          this.searching = false
        } else {
          const tempInvoice = this.inputInvoiceNumber
          this.reset()
          this.errorMessage = `invoice number ${tempInvoice} not found, try another`
        }
      } else {
        this.searching = false
        this.reset()
        this.errorMessage = 'something went wrong, please try again'
      }
    },
    addHashToLocation(params) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        null,
        `${this.$route.path}${params}`,
      )
    },
    async searchSerial() {
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/invoice/ops/${this.inputSerialNumber}?type=serial&key=55d85562ab5f47dba870ae9caf584081`,
        )
        .then(response => {
          this.dataSerialNumber = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async searchTracking() {
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/tracking/view/${this.inputTrackingNumber}?version=2&key=e4bbe18e35044ff0baa9848b7b39bb2c`,
        )
        .then(response => {
          this.dataTrackingNumber = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async searchOrder() {
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/invoice/memo/${this.inputOrderNumber}?version=2&key=55d85562ab5f47dba870ae9caf584081`,
        )
        .then(response => {
          this.dataOrderNumber = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async searchInvoice() {
      const invoiceNo = this.inputInvoiceNumber
      this.reset()
      this.searching = true
      this.inputFocus('invoice')
      this.inputInvoiceNumber = invoiceNo
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `Searching Invoice: ${invoiceNo}`,
          icon: 'BellIcon',
        },
      })
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/invoice/view/${this.inputInvoiceNumber}?version=2&key=55d85562ab5f47dba870ae9caf584081`,
        )
        .then(response => {
          this.dataInvoice = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    async searchInvoiceLines() {
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/invoice/lineitems/${this.dataInvoice.result.txnID}?version=2&key=55d85562ab5f47dba870ae9caf584081`,
        )
        .then(response => {
          this.dataInvoiceLineItems = response.data
        })
        .catch(error => {
          console.log(error)
          this.dataInvoiceLineItems.queryCount = null
        })
    },
    async searchInvoiceTickets() {
      this.searchingInvoiceTicket = true
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/ticket/service/invoice?id=${this.inputInvoiceNumber}&key=836715d7f31643618225af5af3141717`,
        )
        .then(response => {
          this.dataInvoiceTickets = response.data
        })
        .catch(error => {
          console.log(error)
          this.dataInvoiceTickets.queryCount = null
        })
      this.searchingInvoiceTicket = false
    },
    async searchInvoiceOps() {
      this.searchingInvoiceOps = true
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/techs/invoice/${this.inputInvoiceNumber}/wserials?version=2&key=1ea49cfa0a144e7daf97f1e42601ca05`,
        )
        .then(response => {
          this.dataInvoiceOps = response.data
        })
        .catch(error => {
          console.log(error)
          this.dataInvoiceOps.queryCount = null
        })
      this.searchingInvoiceOps = false
    },
    async searchInvoiceImages() {
      this.searchingInvoiceImages = true
      await this.$http
        .get(
          `https://asi-api2.azure-api.net/invoice/images/${this.inputInvoiceNumber}?version=2&key=55d85562ab5f47dba870ae9caf584081`,
        )
        .then(response => {
          this.dataInvoiceImages = response.data
        })
        .catch(error => {
          console.log(error)
          this.dataInvoiceImages.queryCount = null
        })
      this.searchingInvoiceImages = false
    },
    reset() {
      this.inputBlur('reset')
      if (!this.searching) {
        this.$router.push({ name: 'lookup-order' })
      }
      this.messageText = ''
      this.errorMessage = ''
      this.searching = false
      this.searchingInvoiceTicket = false
      this.dataInvoice = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        result: {
          txnID: '',
          refNumber: '',
          timeCreated: '',
          timeModified: '',
          shipMethodRef_FullName: '',
          other: '',
          subtotal: 0.0,
          appliedAmount: 0.0,
          isPaid: true,
          balanceRemaining: 0.0,
          memo: '',
          salesRepRef_ListID: '',
          customerRef_FullName: '',
          salesRepRef_FullName: '',
          billAddress_Addr1: '',
          billAddress_Addr2: '',
          billAddress_Addr3: '',
          billAddress_Addr4: '',
          billAddress_Addr5: '',
          billAddress_City: '',
          billAddress_State: '',
          billAddress_PostalCode: '',
          billAddress_Country: '',
          shipAddress_Addr1: '',
          shipAddress_Addr2: '',
          shipAddress_Addr3: '',
          shipAddress_Addr4: '',
          shipAddress_Addr5: '',
          shipAddress_City: '',
          shipAddress_State: '',
          shipAddress_PostalCode: '',
          shipAddress_Country: '',
        },
      }
      this.dataInvoiceLineItems = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            txnLineID: '',
            itemRef_FullName: '',
            desc: '',
            quantity: 0.0,
            rate: 0.0,
            amount: 0.0,
          },
        ],
      }
      this.dataSerialNumber = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            id: '',
            serialNumber: '',
          },
        ],
      }
      this.dataInvoiceTickets = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            ticketNo: 0,
            status: '',
            statusColor: '',
            operator: [],
            startDate: '',
            updateDate: '',
            summary: '',
            contact: '',
            queue: '',
          },
        ],
      }
      this.dataInvoiceOps = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            ID: 0,
            UID: '',
            timeCreated: '',
            timeModified: '',
            invoiceID: '',
            approvedByID: '',
            builtByID: '',
            buildCompleteDate: '',
            buildComplete: false,
            noOfUnits: 0,
            serialNumbers: [
              {
                ID: 0,
                UID: '',
                timeCreated: '',
                timeModified: '',
                invoiceID: '',
                addedBy: '',
                active: false,
                serialNumber: '',
                model: '',
                pid: '',
              },
            ],
            substitution: false,
            dropShip: false,
            status: '',
            shipDate: '',
            shipDateActual: '',
            invoiceDate: '',
            invoiceProduct: '',
            isShipped: false,
            isDelivered: false,
            tracking: '',
            deliverDateActual: '',
            builtBy: '',
            approvedBy: '',
          },
        ],
      }
      this.dataTrackingNumber = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        result: {
          trackingno: '',
          trackingcarrier: '',
          pickupdate: '',
          pickuplocation: '',
          deliverydate: '',
          status: '',
          class: '',
          destState: '',
          destCity: '',
          invoiceNo: '',
          eventSummary: [
            {
              date: '',
              time: '',
              gmtOffset: '',
              dateTime: '',
              status: '',
              location: '',
              details: '',
              isException: false,
              isDelivered: false,
              isDelException: false,
              isClearanceDelay: false,
            },
          ],
        },
      }
      this.dataOrderNumber = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            txnID: '',
            refNumber: '',
            salesRepRef_FullName: '',
          },
        ],
      }
      this.dataInvoiceImages = {
        queryTime: {
          value: '',
          DisplayHint: 2,
          DateTime: '',
        },
        queryCount: 0,
        results: [
          {
            src: '',
          },
        ],
      }
    },
    inputFocus(input) {
      if (input === 'serial') {
        this.disabledTracking = true
        this.disabledInvoice = true
        this.disabledOrder = true
      } else if (input === 'tracking') {
        this.disabledSerial = true
        this.disabledInvoice = true
        this.disabledOrder = true
      } else if (input === 'invoice') {
        this.disabledSerial = true
        this.disabledTracking = true
        this.disabledOrder = true
      } else if (input === 'order') {
        this.disabledSerial = true
        this.disabledTracking = true
        this.disabledInvoice = true
      }
    },
    inputBlur(input) {
      if (input === 'serial' && !this.inputSerialNumber) {
        this.disabledTracking = false
        this.disabledInvoice = false
        this.disabledOrder = false
      } else if (input === 'tracking' && !this.inputTrackingNumber) {
        this.disabledSerial = false
        this.disabledInvoice = false
        this.disabledOrder = false
      } else if (input === 'invoice' && !this.inputInvoiceNumber) {
        this.disabledSerial = false
        this.disabledTracking = false
        this.disabledOrder = false
      } else if (
        input === 'order'
        && !this.inputOrderNumber
        && !this.selectOrderNumberType
      ) {
        this.disabledSerial = false
        this.disabledTracking = false
        this.disabledInvoice = false
      } else if (input === 'reset') {
        this.disabledSerial = false
        this.disabledTracking = false
        this.disabledInvoice = false
        this.disabledOrder = false
        this.inputSerialNumber = ''
        this.inputTrackingNumber = ''
        this.inputInvoiceNumber = ''
        this.inputOrderNumber = ''
      }
    },
  },
}
</script>
<style>
  .carousel-item img {
      height:70vh!important;
      width:auto!important;
      align-content:center!important;
      display: block;
      margin-left: auto;
      margin-right: auto;
  }
</style>
