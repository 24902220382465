<template>
  <b-card-actions
    action-collapse
    title="Operations Data"
    no-body
  >
    <b-overlay
      :show="show"
      rounded="sm"
    ><b-card-body class="font-medium-1 mt-0 pt-0">
      <b-row>
        <b-col
          md="6"
          cols="12"
        >
          <b-row>
            <b-col cols="6">
              <div v-if="data.results[0].timeCreated">
                {{ data.results[0].timeCreated | moment("MMMM DD, YYYY") }}
              </div>
              <div
                v-if="data.results[0].timeCreated"
                class="font-small-1 mb-1"
              >
                Build Started
              </div>
            </b-col>
            <b-col cols="6">
              <div v-if="data.results[0].buildCompleteDate">
                {{ data.results[0].buildCompleteDate | moment("MMMM DD, YYYY") }}
              </div>
              <div
                v-if="data.results[0].buildCompleteDate"
                class="font-small-1 mb-1"
              >
                Build Completed
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <div v-if="data.results[0].builtBy">
                {{ data.results[0].builtBy }}
              </div>
              <div
                v-if="data.results[0].builtBy"
                class="font-small-1 mb-1"
              >
                Built By
              </div>
            </b-col>
            <b-col cols="6">
              <div v-if="data.results[0].approvedBy">
                {{ data.results[0].approvedBy }}
              </div>
              <div
                v-if="data.results[0].approvedBy"
                class="font-small-1 mb-1"
              >
                Approved By
              </div>
            </b-col>
          </b-row>
          <div v-if="data.results[0].status">
            {{ data.results[0].status }}
          </div>
          <div
            v-if="data.results[0].status"
            class="font-small-1 mb-1"
          >
            Build Status
          </div>
        </b-col>
        <b-col
          md="6"
          cols="12"
        >
          <div
            v-for="serial in data.results[0].serialNumbers"
            :key="serial.id"
          >
            {{ serial.serialNumber }} <span v-if="serial.model"> | {{ serial.model }}</span>
          </div>
          <div class="font-small-1 mb-1">
            Model & Serials
          </div>
          <div v-if="data.results[0].tracking">
            {{ data.results[0].tracking }}
          </div>
          <div
            v-if="data.results[0].tracking"
            class="font-small-1 mb-1"
          >
            Tracking Numbers
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    </b-overlay>
  </b-card-actions>
</template>
<script>
import {
  BOverlay, BRow, BCol, BCardBody,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'

export default {
  components: {
    BCardActions,
    BOverlay,
    BRow,
    BCol,
    BCardBody,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      show: true,
    }
  },
  watch: {
    data(val) {
      if (val.queryCount === 0 || val.queryCount === null) {
        this.show = true
      }
      if (val.queryCount > 0) {
        this.show = false
      }
    },
  },
  mounted() {
  },
  beforeDestroy() {
  },
}
</script>
